/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";

import StaticLayout from "../components/StaticLayout";

const Page = () => (
  <StaticLayout isSingle>
    <main id="main" className="site-main" role="main">
      <section className="error-404 not-found">
        <div className="svg-container svg-block page-header-svg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 1890 150"
          >
            <g transform="translate(0,-902.36218)" />
            <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
            <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
            <path d="m 1925,0 0,150 -1925,0" />
          </svg>
        </div>
        <header className="page-header">
          <h1 className="page-title">
            Oeps! Deze pagina kon niet worden gevonden.
          </h1>
        </header>
        {/* .page-header */}
        <div className="svg-container svg-block page-header-svg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 1950 150"
          >
            <g transform="translate(0,-902.36218)" />
            <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
            <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
            <path d="M 0,150 0,0 1950,0" />
          </svg>
        </div>
        {/* .page-content */}
      </section>
      {/* .error-404 */}
    </main>
  </StaticLayout>
);

export default Page;
